/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { slide as BMenu } from 'react-burger-menu';
import { AuthContext } from '../../context/auth-context';
import { CoreContext } from '../../context/core-context';

const Menu = () => {

    const authContext = useContext(AuthContext);
    const coreContext = useContext(CoreContext);


    const [isOpen, setIsOpen] = useState(false);

    const handleOnOpen = () => {
        setIsOpen(!isOpen);
    };

    return (<div><BMenu isOpen={isOpen} onOpen={handleOnOpen}>
        <Link onClick={handleOnOpen} className="menu-item" to="/">Home</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/logout">Sign Out</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/settings">Settings</Link>

        {authContext.item.categoryId === 1 ?
            <>  <div className="menu-header">Admin</div>
                <Link onClick={handleOnOpen} className="menu-item" to="/features">Features</Link>
                <Link onClick={handleOnOpen} className="menu-item" to="/categories">Categories</Link>
                <Link onClick={handleOnOpen} className="menu-item" to="/roles">Roles</Link>
                <Link onClick={handleOnOpen} className="menu-item" to="/districts">Districts</Link>
                <Link onClick={handleOnOpen} className="menu-item" to="/states">States</Link>
                <Link onClick={handleOnOpen} className="menu-item" to="/countries">Countries</Link>
            </> : ''
        }

        <div className="menu-header">Admin</div>
        <Link onClick={handleOnOpen} className="menu-item" to="/menus">Menus</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/users">Users</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/add-user">Add User</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/self">My Profile</Link>

        <div className="menu-header">Front Page</div>
        <Link onClick={handleOnOpen} className="menu-item" to='/sliders'>Slider</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/educational-solutions">Educational Solutions</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/enroll-today">Enroll Today</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/register-now">Register Now</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/teachers">Teachers</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/testimonials">Testimonials</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/events">Events</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/generals">General Items</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/blogs">Latest News / Blogs</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/fun-facts">Fun Facts</Link>

        {authContext.item.categoryId === 1 || authContext.item.categoryId === 2 ?
            <>
                <div className="menu-header">Reseller</div>
                <Link onClick={handleOnOpen} className="menu-item" to="/clients">Clients</Link>
                <Link onClick={handleOnOpen} className="menu-item" to="/sign-up">Add Client</Link>

            </> : ''
                }

        <div className="menu-header"></div>
        <Link onClick={handleOnOpen} className="menu-item" to="/pages">Pages</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/images">Images / Docs</Link>
        <Link onClick={handleOnOpen} className="menu-item" to="/videos">You Tube Videos</Link>
        <div className="menu-header">End</div>
    </BMenu><center><span style={{ fontWeight: 'bold', backgroundColor: '#dc3545', color: 'white', fontSize: 'x-large', textDecoration: 'underline', padding: '20px', borderRadius: '200px' }}>{coreContext.client.item_name ? coreContext.client.item_name : 'Website Builder'}</span></center></div>);

}

export default Menu;
