/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import Input from './common/Input';
import { SingleTestimonial } from './SingleTestimonial';
import { Badge } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useNavigate } from 'react-router-dom';


const Testimonial = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    const navigate = useNavigate();
    const fetchClient = () => {
        coreContext.fetchSelfTestimonial(authContext.itemId);
    }

    useEffect(fetchClient, []);

    const renderImages = () => {
        const images = coreContext.testimonialImages;
        if (images)
            return images.map(image => <SingleTestimonial key={image.id} image={image} />)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;

        for await (const image of selectedImages) {
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadTestimonialImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchTestimonialImages(coreContext.client.id);
                }, 2000);

                setTimeout(() => {
                    //  navigate('/');
                }, 5000);
            };
        }



    }


    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Testimonial Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 200*200 </div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} selectedImages={selectedImages} maxWidthOrHeight='200' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Testimonial Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { Testimonial }