/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import { Pencil, XSquare, Check2Square } from 'react-bootstrap-icons';
import { Badge } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { Link } from 'react-router-dom';




const Pages = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const [dragItem, setDragItem] = useState('');
    const [sortedPages, setSortedPages] = useState([]);


    const fetchClient = () => {
        coreContext.fetchSelfPages(authContext.itemId);
    }


    useEffect(fetchClient, []);

    const comparePages = (a, b) => {
        if (a.display_order < b.display_order) {
            return -1;
        }
        if (a.display_order > b.display_order) {
            return 1;
        }
        return 0;
    }


    const findPageByName = (name) => {
        let filteredPage = {};
        sortedPages.forEach(page => {

            if (page.title.trim() === name.trim()) {
                filteredPage = page;
            }
        })
        return filteredPage;

    }

    const exchangeDisplayOrder = (dragItemText, overItemText) => {
        const dragItem = findPageByName(dragItemText);
        const overItem = findPageByName(overItemText);
        let sPages = [];

        let ex = dragItem.display_order;
        dragItem.display_order = overItem.display_order;
        overItem.display_order = ex;

        if (sortedPages.length === 0)
            sPages = [...coreContext.pages];
        else sPages = [...sortedPages];
        sPages.forEach(spage => {
            if (spage.id === dragItem.id) {
                spage = { ...spage, display_order: dragItem.display_order }
            }
            if (spage.id === overItem.id) {
                spage = { ...spage, display_order: overItem.display_order }
            }
        });

        setSortedPages(sPages);

    }

    const onDragOver = (e) => {
        e.preventDefault();
        const current = e.target.innerHTML;
        exchangeDisplayOrder(dragItem, current);

    }

    const onDrop = (e) => {

        const sPages = sortedPages.sort(comparePages)
        coreContext.setPages(sPages);
        coreContext.updatePageDisplayOrder(sPages);
    }

    const onDragStart = (e) => {
        setDragItem(e.target.innerHTML);
    }


    const deletePage = (id) => {
        const r = confirm("Delete Page ?");
        if (r === true) {
            coreContext.deletePage(id, coreContext.client.id, coreContext.client.auto_menu);
        } else {

        }


    }

    const retrivePage = (id) => {
        coreContext.deletePage(id, coreContext.client.id, coreContext.client.auto_menu);

    }

    const renderPages = () => {
        const pages = coreContext.pages;
        if (pages)
            return pages.map(page => {
                if (page.status === 'active')
                    return <tr key={page.id}><td draggable onDrop={(e) => onDrop(e)} onDragOver={(e) => onDragOver(e)} onDragStart={(e) => onDragStart(e)}>{page.title} </td><td> {page.menu}</td><td><Link className="btn btn-primary btn-sm" title='Edit page' to={`/page/${page.id}`}><Pencil size="12" /></Link></td><td><button onClick={() => deletePage(page.id)} type="button" className="btn btn-danger btn-sm" title="Delete Page"> <XSquare size="12" /> </button></td></tr>
                else return <tr style={{ textDecoration: 'line-through' }} key={page.id}><td>{page.title}</td><td> {page.menu}</td><td><a className="btn btn-primary btn-sm" title='Edit page' href={`page/${page.id}`}><Pencil size="12" /></a></td><td><button onClick={() => retrivePage(page.id)} type="button" className="btn btn-danger btn-sm" title="Retrieve Page"> <Check2Square size="12" /> </button></td></tr>

            })
    }




    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-body">
                <Link to="/add-page">Add Pages</Link>
            </div>
        </div>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Website Pages</Badge></h3></div>
            <div className="card-body">
                <table className="table table-bordered table-sm">
                    <thead>
                        <tr><th>Title</th><th>Menu</th><th></th><th></th></tr>
                    </thead>
                    <tbody >
                        {renderPages()}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export { Pages }