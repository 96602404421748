/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import ImageUploader from './common/UploadImage';
import Input from './common/Input';
import { SingleSlider } from './SingleSlider';
import { Badge } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useNavigate } from 'react-router-dom';

const Slider = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);

    const navigate = useNavigate();

    const fetchClient = () => {
        coreContext.fetchSelf(authContext.itemId);
    }

    useEffect(fetchClient, []);

    const renderImages = () => {
        const images = coreContext.homeSliderImages;
        if (images)
            return images.map(image => <SingleSlider key={image.id} image={image} />)
    }

    const onImagesUploadHandler = async () => {
        const count = selectedImages.length;
        let counter = 0;
        // const preImagesCount = images.length;

        // const imagesNowAllowed = maxImagesAllowed - preImagesCount;

        // if (count > imagesNowAllowed) { alert('You can now upload a maximum of ' + imagesNowAllowed + ' images.'); return }

        for await (const image of selectedImages) {
            console.log(image);
            ++counter;
            const formData = new FormData();
            formData.append('itemId', coreContext.client.id);
            formData.append('image', image, image.name);
            formData.append('userId', authContext.userId);
            coreContext.uploadSliderImage(formData, coreContext.client.id);

            if (counter === count) {
                setTimeout(() => {
                    setSelectedImages([]);
                    coreContext.fetchSliderImages(coreContext.client.id);
                }, 2000);

                setTimeout(() => {
                    // navigate('/');
                }, 5000);
            };
        }



    }

    const onImagesSelected = (pictures) => {
        setSelectedImages(pictures);
        //  console.log(pictures);
        if (pictures.length > 0) setUploadButtonDisabled(false); else setUploadButtonDisabled(true);
    }

    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Upload Slider Images</Badge></h3></div>
            <div className="card-title mx-auto">Best Image Size 1920*820</div>
            <div className="card-body">
                <ImageUploader onImagesSelected={onImagesSelected} maxWidthOrHeight='1920' pictures={selectedImages} />
                <Input value='Upload Images' onClick={onImagesUploadHandler} disabled={uploadButtonDisabled} elementType='button' variant='primary' />&nbsp;&nbsp; {coreContext.renderLoader()}
                <Input variant='danger' label={coreContext.message} elementType='label' />
            </div>
            <div className="card-body">
                <div className="card-title mx-auto"><h3><Badge variant="success">Slider Images</Badge></h3></div>
                <div className="row">
                    {renderImages()}
                </div>
            </div>
        </div>
    </div>
    )
}

export { Slider }