/* eslint-disable no-multi-str */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useNavigate } from 'react-router-dom';
import Input from './common/Input';
import { Badge, Form } from 'react-bootstrap';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';
import { useForm } from "react-hook-form";


const AddBlog = (props) => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);
    const [content, setContent] = useState('Enter Page Content...');
    const [showInPopUP, setShowInPopUP] = useState('no');

    const navigate = useNavigate();

    const redirect = () => {
        if (coreContext.isRequestComplete) navigate('/blogs');
    }

    useEffect(redirect, [coreContext.isRequestComplete]);

    const { register, handleSubmit, formState: { errors } } = useForm();



    const handleEditorChange = (content, editor) => {
        setContent(content);
    }
    const showInPopUpChangeHandler = (e) => {
        if (showInPopUP === 'yes')
            setShowInPopUP('no');
        else
            setShowInPopUP('yes');
    }

    const onBlogContentHandler = (data) => {
        coreContext.addBlogContent(data.title, data.message, content, data.edate, data.cat, showInPopUP, authContext.itemId, authContext.userId);

    }


    return (<div className='container-fluid'>
        <div className="card">
            <div className="card-title mx-auto"><h3><Badge variant="success">Add New Blog</Badge></h3></div>
            <div className="card-body">
                <Form autoComplete='off' onSubmit={handleSubmit(onBlogContentHandler)} noValidate>
                    <Input label='Title' name='title' minLength={2} maxLength={155} required={true} register={register} errors={errors} elementType='textarea' placeholder='Enter Title' />

                    <Input label='Message' name='message' minLength={2} maxLength={2000} required={true} register={register} errors={errors} elementType='textarea' placeholder='Enter Message' />

                    <div className="row"><div className="col-md-4">
                        <Input label='Blog Date' name='edate' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='date' placeholder='Enter Blog Date' />
                    </div>
                        <div className="col-md-4">
                            <Input label='Blog Category' name='cat' minLength={2} maxLength={50} required={true} register={register} errors={errors} elementType='text' placeholder='Enter Blog Category : eg. Education or Promotion' />
                        </div>
                        <div className="col-md-4">
                            <Input elementType='checkbox' value="Show In Pop-UP" onChange={showInPopUpChangeHandler} />
                        </div>
                    </div>

                    <Form.Group>
                        <Editor
                            apiKey={'5k5x5ez6z06mvgu1fkuizuc0wa07bqni14qml1baljvj2ijp'}
                            value={content}
                            init={{
                                height: 500,
                                menubar: 'insert',
                                file_picker_types: 'file image media',
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor forecolor  | fontsize fontfamily lineheight | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent image | removeformat | code | help'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </Form.Group>
                    <Input elementType='button' variant='primary' value="Update" /> &nbsp;&nbsp; {coreContext.renderLoader()}
                    <Input variant='danger' label={coreContext.message} elementType='label' />
                </Form>
            </div>
        </div>
    </div>
    )
}

export { AddBlog }