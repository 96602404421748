/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import { CoreContext } from '../context/core-context';
import { AuthContext } from '../context/auth-context';

const CoachingListAdmin = () => {

    const coreContext = useContext(CoreContext);
    const authContext = useContext(AuthContext);

    const fetchClient = () => {

        coreContext.fetchSelf(authContext.itemId);

    }

    useEffect(fetchClient, []);

    return (
        <center>

            <h5><a target="_blank" href="http://web1.siddhantait.com">View Sample Website</a></h5>
            <h5><a target="_blank" href={"http://" + coreContext.client.site_url}>Show My Website in new Tab</a></h5>
            <iframe title="My Website" width="1200px" height="800px" src={"https://" + coreContext.client.site_url} frameborder="0"></iframe>



        </center>
    );

}

export { CoachingListAdmin };