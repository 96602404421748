import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context/auth-context';
import { Navigate } from 'react-router-dom';
const LogOut = () => {

    const authContext = useContext(AuthContext);

    useEffect(authContext.logOut, []);
    return <Navigate to='/' />;

}


export { LogOut };