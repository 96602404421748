import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { PencilSquare, XSquare } from 'react-bootstrap-icons';
import { CoreContext } from '../context/core-context';
import { Badge, Form } from 'react-bootstrap';
import Input from './common/Input';
import MyModal from './common/MyModal';


const Role = (props) => {
    const coreContext = useContext(CoreContext);


    const { register, handleSubmit, errors, setValue } = useForm();

    const [name, setName] = useState('');
    const [mode, setMode] = useState('create');
    const [disabled, setDisabled] = useState(true);
    const [editCat, setEditCat] = useState({});
    const [categoryTitle, setCategoryTitle] = useState('Add Role');
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState('');


    const renderCreatUpdateButtons = () => {

        if (mode === 'create') {
            return <Input value='Add' elementType='button' variant='primary' />
        } else if (mode === 'edit') {
            return <Input value='Edit' elementType='button' variant='dark' />
        }
    }

    const handleModalOpen = (cat) => {
        setShowModal(true);
        setMode('create');
        setName('');
        setEditCat(cat);
        setModalBody(cat.role_name + ' will be deleted permanently !');
    }
    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleModalSubmit = (id) => {
        deleteRole(id);
        setShowModal(false);
    }

    const cancelUpdate = () => {
        setMode('create');
        setValue('name', '');
        setEditCat({});
        setCategoryTitle('Add Role');
    }

    const renderCancelUpdateButton = () => {

        if (mode === 'create') {
            return '';
        } else if (mode === 'edit') {
            return <Input value='Cancel Edit' elementType='button' variant='warning' type='button' onClick={cancelUpdate} />
        }
    }

    const onSubmit = (data) => {
        if (mode === 'create') addRole(data.name);
        else if (mode === 'edit') editRole(data.name);
    }


    const editName = (cat) => {
        setValue('name', cat.role_name);
        setMode('edit');
        setDisabled(true);
        setEditCat(cat);
        coreContext.setMessage('');
        setCategoryTitle('Edit Role');
    }

    const deleteRole = (id) => {
        coreContext.deleteRole(id);
        setEditCat({});
    }

    const addRole = (n) => {
        coreContext.addRole(n);
        setValue('name', '');
    }
    const editRole = (n) => {
        coreContext.editRole(n, editCat.id);
        setEditCat({});
        setMode('create');
        setValue('name', '');
    }

    const renderCategories = () => {
        if (coreContext.roles) {
            return coreContext.roles.map((cat, index) => (
                <tr key={++index}>
                    <td>{++index}</td>
                    <td>{cat.role_name}</td>
                    <td><button onClick={() => editName(cat)} type="button" className="btn btn-success btn-sm" title="Edit Role"><PencilSquare size="12" /></button>&nbsp;&nbsp;&nbsp;<button onClick={() => handleModalOpen(cat)} type="button" className="btn btn-danger btn-sm" title="Delete Role"><XSquare size="12" /></button></td>
                </tr>
            ));
        }
        else {
            return (<tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>)
        }
    }

    return (
        <div className="row">
            <div className='col-md-4'>
                <div className="card" style={{ position: 'sticky', top: '50px' }}>
                    <div className="card-title mx-auto">
                        <h3>
                            <Badge variant="success">{categoryTitle}</Badge>
                        </h3>
                    </div>
                    <div className="card-body">
                        <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Input label='Name' name='name' value={name} minLength={3} maxLength={55} required={true} register={register}
                                errors={errors} elementType='text' placeholder='Enter Role Name' />
                            {renderCreatUpdateButtons()} &nbsp;&nbsp; {renderCancelUpdateButton()} &nbsp;&nbsp; {coreContext.renderLoader()}
                            <Input variant='danger' label={coreContext.message} elementType='label' />
                        </Form>
                    </div>
                </div>
            </div>
            <div className='col-md-8'>
                <div className="card">
                    <div className="card-title mx-auto">
                        <h3>
                            <Badge variant="success">Roles</Badge>
                        </h3>
                    </div>
                    <div className="card-body table-responsive">
                        <table className='table table-sm table-bordered'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderCategories()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <MyModal handleModalClose={handleModalClose} handleModalSubmit={handleModalSubmit} showModal={showModal} modalBody={modalBody} cat={editCat} />
        </div>
    );
}


export { Role };